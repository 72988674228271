<template>
  <VRow class="ma-0 pa-0">
    <VCol
      v-if="label && !useMaterials"
      cols="12"
      align="start"
      class="ma-0 pa-0"
    >
      <p class="font-weight-medium mb-0 text--primary--theme">
        {{ label }}
      </p>
    </VCol>
    <VCol cols="12" class="ma-0 pa-0" :class="{ 'pt-1': !useMaterials }">
      <VCard v-bind="$attrs">
        <VList>
          <VListItem v-for="(item, index) in items" :key="index" dense>
            <VListItemContent>
              <VListItemTitle>
                <slot name="title" :item="item" :index="index"></slot>
              </VListItemTitle>
              <VListItemSubtitle>
                <slot name="subtitle" :item="item" :index="index"></slot>
              </VListItemSubtitle>
            </VListItemContent>
            <VListItemAction>
              <slot name="action1" :item="item" :index="index"> </slot>
            </VListItemAction>
            <VListItemAction>
              <slot name="action2" :item="item" :index="index"> </slot>
            </VListItemAction>
            <VListItemAction>
              <slot name="action3" :item="item" :index="index"> </slot>
            </VListItemAction>
          </VListItem>
          <VListItem v-if="items.length === 0">
            <VListItemContent class="d-flex justify-center">
              {{ $t(emptyLabel || "emptyLabel") }}
            </VListItemContent>
          </VListItem>
          <VListItem v-if="addButon" dense>
            <VListItemContent dense class="d-flex justify-end">
              <VBtn icon @click="$emit('add-item')">
                <VIcon>mdi-plus</VIcon>
              </VBtn>
            </VListItemContent>
          </VListItem>
        </VList>
      </VCard>
    </VCol>
  </VRow>
</template>

<script>
export default {
  name: "DresskareTextField",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
    useMaterials: {
      type: Boolean,
      default: false,
    },
    addButon: {
      type: Boolean,
      default: false,
    },
    emptyLabel: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      internalValue: [],
    };
  },
};
</script>


<i18n>
  {
    "en": {
      "emptyLabel": "No item"
    },
    "fr": {
      "emptyLabel": "Aucun élément"
    }
  }
  </i18n>
  